html {
  font-size: 62.5%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  height: 100%;
  min-height: 100vw;
}

body {
  font-size: 1.8rem;
  line-height: 1.618;
  color: #c9c9c9;
  background-color: rgba(40, 46, 65, 1);
  height: 100%;
  min-height: 100vw;
  padding-bottom: 48px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
}

h1 {
  font-size: 2.35em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.25em;
}

h6 {
  font-size: 1em;
}

p {
  margin-top: 0px;
  margin-bottom: 2.5rem;
}

a {
  text-decoration: none;
  color: #ffffff;
}
a:hover {
  color: #c9c9c9;
  border-bottom: 2px solid #c9c9c9;
}
a:visited {
  color: #e6e6e6;
}

/* Buttons, forms and input */
input,
textarea {
  border: 1px solid #c9c9c9;
}
input:focus,
textarea:focus {
  border: 1px solid #ffffff;
}

textarea {
  width: 100%;
}

textarea,
select,
input {
  color: #c9c9c9;
  padding: 6px 10px;
  /* The 6px vertically centers text on FF, ignored by Webkit */
  margin-bottom: 10px;
  background-color: #4a4a4a;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  box-shadow: none;
}
textarea:focus,
select:focus,
input:focus {
  border: 1px solid rgba(255, 255, 255, 0.5);
  outline: 0;
}

input[type="checkbox"]:focus {
  outline: 1px dotted #ffffff;
}

label,
legend,
fieldset {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
